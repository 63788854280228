<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id"
    entity-name="结缘品"
    add-btn="添加结缘品"
    hide-show-detail
    hide-edit
    hide-delete
    show-row-num
    :rules="rules"
    :columns="columns"
    :search-key-type="searchKeyType"
    :action="action"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="结缘品名称">
        <a-input v-model="form.name" placeholder="输入结缘品名称查询" allow-clear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="结缘品名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入结缘品名称" allow-clear></a-input>
      </a-form-model-item>
      <a-form-model-item label="发放条件" prop="is_limit">
        <a-radio-group :options="grantOptions" v-model="form.is_limit"></a-radio-group>
      </a-form-model-item>
      <template v-if="form.is_limit == 2">
        <a-form-model-item label="可进行领取的志工状态（多选）">
          <a-select placeholder="请选择志工状态" :options="statusOptions" v-model="form.volunteer_status"></a-select>
        </a-form-model-item>
        <a-form-model-item label="仅以下志工可领取">
          <upload-volunteer v-model="form.volunteer_id"></upload-volunteer>
        </a-form-model-item>
      </template>
    </template>
    <template v-slot:action="{ item, index }">
      <a-space class="row-btn">
        <a class="txt-btn" @click.stop="editRow(item, index)" v-action:update>编辑</a>
        <a class="txt-btn danger" @click.stop="delRow(item, index)" v-if="!item.is_fixed" v-action:delete>删除</a>
        <a class="txt-btn" @click.stop="goList(item, index)" v-if="item.is_fixed">查看名单</a>
      </a-space>
    </template>
  </data-list>
</template>

<script>
import { grantOptions, volunStatusOptions } from "../../../common/hr/volunteer";
import { clone, getKeyTitle } from "../../../common/js/tool";

export default {
  name: "OpeSouvenir",
  data() {
    return {
      url: "/admin/volunteer-souvenir",
      searchKeyType: {
        name: 1,
      },
      columns: [
        { title: "结缘品名称", dataIndex: "name" },
        {
          title: "发放条件",
          dataIndex: "is_limit",
          customRender: (text) => <span>{getKeyTitle(grantOptions, text, "value", "label")}</span>,
        },
      ],
      rules: {
        name: [{ required: true, message: "请输入结缘品名称", trigger: "blur" }],
        is_limit: [{ required: true, message: "请选择发放条件", trigger: "change" }],
      },
      grantOptions,
      statusOptions: clone(volunStatusOptions).splice(0, 3),
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  created() {},
  methods: {
    updateList() {
      this.dataList.updateList();
    },
    editRow(item, index) {
      this.$refs.dataList.editRow(item, index);
    },
    delRow(item, index) {
      this.$refs.dataList.delRow(item, index);
    },
    goList() {
      this.$router.push({ name: "Manual" });
    },
  },
};
</script>

<style lang="less" scoped></style>
