var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-list',{ref:"dataList",staticClass:"content md",attrs:{"url":_vm.url,"query":"&sort=-id","entity-name":"结缘品","add-btn":"添加结缘品","hide-show-detail":"","hide-edit":"","hide-delete":"","show-row-num":"","rules":_vm.rules,"columns":_vm.columns,"search-key-type":_vm.searchKeyType,"action":_vm.action},scopedSlots:_vm._u([{key:"query",fn:function(ref){
var form = ref.form;
return [_c('a-form-model-item',{attrs:{"label":"结缘品名称"}},[_c('a-input',{attrs:{"placeholder":"输入结缘品名称查询","allow-clear":""},model:{value:(form.name),callback:function ($$v) {_vm.$set(form, "name", $$v)},expression:"form.name"}})],1)]}},{key:"edit",fn:function(ref){
var form = ref.form;
return [_c('a-form-model-item',{attrs:{"label":"结缘品名称","prop":"name"}},[_c('a-input',{attrs:{"placeholder":"请输入结缘品名称","allow-clear":""},model:{value:(form.name),callback:function ($$v) {_vm.$set(form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"发放条件","prop":"is_limit"}},[_c('a-radio-group',{attrs:{"options":_vm.grantOptions},model:{value:(form.is_limit),callback:function ($$v) {_vm.$set(form, "is_limit", $$v)},expression:"form.is_limit"}})],1),(form.is_limit == 2)?[_c('a-form-model-item',{attrs:{"label":"可进行领取的志工状态（多选）"}},[_c('a-select',{attrs:{"placeholder":"请选择志工状态","options":_vm.statusOptions},model:{value:(form.volunteer_status),callback:function ($$v) {_vm.$set(form, "volunteer_status", $$v)},expression:"form.volunteer_status"}})],1),_c('a-form-model-item',{attrs:{"label":"仅以下志工可领取"}},[_c('upload-volunteer',{model:{value:(form.volunteer_id),callback:function ($$v) {_vm.$set(form, "volunteer_id", $$v)},expression:"form.volunteer_id"}})],1)]:_vm._e()]}},{key:"action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('a-space',{staticClass:"row-btn"},[_c('a',{directives:[{name:"action",rawName:"v-action:update",arg:"update"}],staticClass:"txt-btn",on:{"click":function($event){$event.stopPropagation();return _vm.editRow(item, index)}}},[_vm._v("编辑")]),(!item.is_fixed)?_c('a',{directives:[{name:"action",rawName:"v-action:delete",arg:"delete"}],staticClass:"txt-btn danger",on:{"click":function($event){$event.stopPropagation();return _vm.delRow(item, index)}}},[_vm._v("删除")]):_vm._e(),(item.is_fixed)?_c('a',{staticClass:"txt-btn",on:{"click":function($event){$event.stopPropagation();return _vm.goList(item, index)}}},[_vm._v("查看名单")]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }